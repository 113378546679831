import React from 'react';
import {Filter, ViewTable, Layout, Dialog} from '@/components';
import columns from './product_list_columns';
import WebApi, { YchApi } from '@/web/api';
import { observer } from 'mobx-react';
import globalUserStore from '@/global_store/globalUserStore';
import { LibAuthCode } from 'lib/Auth';
import { routerMap } from '@/router/agent_router';
import { hideLoading, showLoading } from '@/components/message/message';
import LibEnum from 'lib/enum';

interface IProps extends RouterPropsType {
  status: string;
}

@observer
export default class ProductListTabPane extends React.PureComponent<IProps> {

  private filterRef = React.createRef<Filter>();
  private viewTable = React.createRef<ViewTable>();
  private params = {
    status: this.props.status,
    attributes: ['sku', 'image', 'activity', 'unit'] as any,
  }
  private queryParams = {
    keyword: '',
    categoryId: '',
  }

  state: {
    selectedRowKeys: string[]
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  private filterFields = [
    {
      type: 'category',
    },
    {
      type: 'input',
      placeholder: '商品名称/条形码',
      field: 'keyword',
      name: '搜索',
    }
  ]

  render() {
    const { selectedRowKeys } = this.state;

    return <Layout.TabPage>
      <Filter ref={this.filterRef} ych fields={this.filterFields as any} onChange={(data: any) => {
        this.queryParams = data;
        this.viewTable.current?.reset(this.queryParams);
      }}></Filter>

      <ViewTable
        ych
        flex1
        ref={this.viewTable}
        columns={columns as any}
        fetch={this.fetch}
        rowKey={row => row.id}
        rowSelection={globalUserStore.isYch() ? undefined : {
          selectedRowKeys,
          onChange: (rowKeys) => {
            this.setState({
              selectedRowKeys: rowKeys,
            });
          }
        }}
        actionButtons={this.actionButtons as any}
      />
    </Layout.TabPage>
  }
  
  private fetch = (data) => {
    if (globalUserStore.from === 'YCH') {
      return YchApi.product_paging({...data, ...this.params, ...this.queryParams});
    }
    return WebApi.product_paging({...data, ...this.params, ...this.queryParams})
  }

  private clickToUpdatePage = (row) => {
    this.props.history.push(routerMap.productUpdate.getPath(row.productId));
  }

  private clickDeleteProduct = (row) => {
    Dialog.confirm({
      content: `确认删除商品 “${row.productName}” 吗，删除后无法恢复`,
      onOk: () => {
        showLoading();
        WebApi.product_delete({productId: row.productId}).then(() => {
          showSuccess.delete();
          this.refresh();
        }).finally(() => {
          hideLoading();
        })
      }
    });
  }

  refresh = () => {
    this.viewTable.current?.refresh();
  }

  reset = () => {
    this.setState({
      selectedRowKeys: [],
    });
    this.filterRef.current?.reset();
    this.viewTable.current?.reset(this.params);
  }

  getSelectedKeys = () => {
    return this.state.selectedRowKeys as string[];
  }

  private actionButtons = [
    {
      type: 'update',
      authCode: LibAuthCode.product.update,
      onClick: this.clickToUpdatePage
    },
    {
      type: 'delete',
      hidden: this.props.status === LibEnum.PRODUCT_STATUS.UP.value,
      authCode: LibAuthCode.product.delete,
      onClick: this.clickDeleteProduct
    },
  ]
}
