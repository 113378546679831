import React from 'react';
import { Tag } from 'antd';
import { routerMap } from '@/router/agent_router';
import { PRODUCT_STATUS } from '@/enum-data/enum';
import globalUserStore from '@/global_store/globalUserStore';
import { Link } from '@/components';

const list = [
  {
    title: '主图',
    dataIndex: 'imageLogo',
  },
  // {
  //   title: '商品编号',
  //   dataIndex: 'id',
  //   width: 200,
  //   align: 'center',
  //   render: (text, record) => <Link to={routerMap.product_detail.getPath(record.id)}>{text}</Link>,
  // },
  {
    title: '商品名称',
    dataIndex: 'productName',
    width: 200,
    render: (text, record) => {
      if (globalUserStore.isYch()) return text;
      return <Link to={routerMap.product_detail.getPath(record.id)}>{text}</Link>
    },
  },
  {
    title: '条形码',
    dataIndex: 'barcode',
    width: 200,
  },
  {
    title: '分类',
    dataIndex: 'categoryName',
    width: 120,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    render: (v, row) => {
      if (v === PRODUCT_STATUS.UP.value) return <Tag color="#87d068">{row.statusDesc}</Tag>
      if (v === PRODUCT_STATUS.DOWN.value) return <Tag color="#f50">{row.statusDesc}</Tag>
      return <Tag color="#f0f0f0">{row.statusDesc}</Tag>
    }
  },
  {
    title: '营销活动',
    width: 200,
    render: (v, row) => {
      if (!row.activity) return '无';
      return row.activity.activityName
    },
  },
  {
    title: '现价(元)',
    dataIndex: 'skuList',
    width: 120,
    align: 'right',
    render: v => {
      if (!v) return '--';
      const prices: number[] = Array.from(new Set(v.map(s => Number(s.price))));
      if (prices.length === 1) return prices[0];
      const min = Math.min.apply(null, prices);
      const max = Math.max.apply(null, prices);
      return min + ' ~ ' + max
    }
  },
  {
    title: '原价(元)',
    dataIndex: 'skuList',
    width: 120,
    align: 'right',
    render: v => {
      if (!v) return '--';
      const prices: number[] = Array.from(new Set(v.map(s => Number(s.originalPrice))));
      if (prices.length === 1) return prices[0];
      const min = Math.min.apply(null, prices);
      const max = Math.max.apply(null, prices);
      return min + ' ~ ' + max
    }
  },
  {
    title: '起购数量',
    align: 'right',
    width: 80,
    dataIndex: 'minBuyCount',
  },
  {
    title: '主计量单位',
    width: 100,
    align: 'right',
    render: (v, row) => {
      const base = row.unitList.find(u => u.ratio === 1);
      return base.unitName;
    }
  },
  {
    title: '辅计量单位',
    width: 200,
    align: 'right',
    render: (v, row) => {
      const base = row.unitList.find(u => u.ratio === 1);
      return row.unitList.map(u => {
        if (u.ratio === 1) {
          return u.unitName;
        }
        return u.unitName + `(${u.ratio}${base.unitName})`
      }).join(' / ')
    }
  },
  {
    title: '搜索关键词',
    dataIndex: 'searchWordList',
    width: 300,
    render: (v) => {
      return v.join('、');
    }
  },
  {
    title: '排序值',
    dataIndex: 'sortValue',
    width: 100,
    sorter: true,
    align: 'right',
  },
  {
    title: '规格数量',
    dataIndex: 'skuList',
    width: 100,
    align: 'right',
    render: v => v.length || 0
  },
];

export default list;
