import React from 'react';
import { message, Menu, Tabs, Dropdown } from 'antd';
import { DownOutlined} from  '@ant-design/icons';
import {CategorySelectDialog, Layout, Button} from '@/components';
import {Dialog} from '@/components';
import { routerMap } from '@/router/agent_router';
import ProductListTabPane from './ProductListTabPane';
import LibEnum from 'lib/enum';
import WebApi from '@/web/api';
import { observer } from 'mobx-react';
import globalUserStore from '@/global_store/globalUserStore';

@observer
export default class ProductListPage extends React.PureComponent<RouterPropsType> {

  private upList = React.createRef<ProductListTabPane>();
  private downList = React.createRef<ProductListTabPane>();
  private categoryDialog = React.createRef<CategorySelectDialog>();

  state: {
    activeTab: string
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'up',
    };
  }

  render() {

    const { activeTab } = this.state;

    return <Tabs className="app-tabs" activeKey={activeTab} onChange={tab => {
      this.setState({
        activeTab: tab
      });
    }} animated={false} tabBarExtraContent={
      globalUserStore.from === 'YCH' ? null : <Layout.TabBarExtra>
        <CategorySelectDialog ref={this.categoryDialog} onOk={this.updateCategoryOk} levels={['level2']}/>
        <Button type="primary" onClick={() => {
          this.props.history.push(routerMap.productCreate.path);
        }} style={{marginLeft: 20}}>新增商品</Button>
        <Dropdown overlay={
          <Menu>
            {
              activeTab === 'down' &&
              <Menu.Item>
                <Button icon={<i className="iconfont icon-shangjia"/>} style={{background: 'none'}} type="default" onClick={() => {
                  this.clickUpdateStatus('11');
                }}>上架商品</Button>
              </Menu.Item>
            }
            {
              activeTab === 'up' &&
              <Menu.Item>
                <Button icon={<i className="iconfont icon-xiajia" />} style={{background: 'none'}} type="default" onClick={() => {
                  this.clickUpdateStatus('12');
                }}>下架商品</Button>
              </Menu.Item>
            }
            <Menu.Item>
              <Button.Edit style={{background: 'none'}} onClick={this.clickUpdateCategory}>
                修改分类
              </Button.Edit>
            </Menu.Item>
          </Menu>
        }>
          <Button type="default" icon={<i className="iconfont icon-piliangcaozuo"/>}>批量操作 <DownOutlined style={{color: '#000', marginLeft: 3}}/></Button>
        </Dropdown>
        <Button.Refresh onClick={() => {
          if (activeTab === 'up') {
            this.upList.current?.refresh();
          } else {
            this.downList.current?.refresh();
          }
        }}/>
      </Layout.TabBarExtra>
    }>
      <Tabs.TabPane key="up" tab="在售商品">
        <ProductListTabPane ref={this.upList} history={this.props.history} status={LibEnum.PRODUCT_STATUS.UP.value}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="down" tab="下架商品">
        <ProductListTabPane ref={this.downList} history={this.props.history} status={LibEnum.PRODUCT_STATUS.DOWN.value}/>
      </Tabs.TabPane>
    </Tabs>
  }

  // 修改状态
  clickUpdateStatus = status => {
    const { activeTab } = this.state;
    let productIdList = (activeTab === 'up' ? this.upList.current?.getSelectedKeys() : this.downList.current?.getSelectedKeys()) as string[];
    if (!productIdList || !Array.isArray(productIdList) || productIdList?.length === 0) {
      message.warning('请至少选择一个商品');
      return;
    }
    let content = '';
    if (status === '11') {
      content = `确认批量上架 ${productIdList.length} 个商品吗?`;
    } else {
      content = `确认批量下架 ${productIdList.length} 个商品吗?`;
    }
    Dialog.confirm({
      title: '确认',
      content,
      onOk: () => {

        WebApi.product_batch_update_status({
          productIdList,
          status,
        })
        .then(() => {
          showSuccess('保存成功');
          if (activeTab === 'up') {
            this.upList.current?.reset();
          } else {
            this.downList.current?.reset();
          }
        })
      }
    });
  };

  // 点击修改分类
  clickUpdateCategory = () => {
    const { activeTab } = this.state;
    let productIdList = (activeTab === 'up' ? this.upList.current?.getSelectedKeys() : this.downList.current?.getSelectedKeys()) as string[];
    if (!productIdList || !Array.isArray(productIdList) || productIdList?.length === 0) {
      message.warning('请至少选择一个商品');
      return;
    }
    this.categoryDialog.current?.open();
  }

  // 确定修改分类
  updateCategoryOk = (data) => {
    let productIdList = (this.state.activeTab === 'up' ? this.upList.current?.getSelectedKeys() : this.downList.current?.getSelectedKeys()) as string[];
    WebApi.product_batch_update_category({
      productIdList,
      categoryId: data.categoryId,
    })
    .then(() => {
      showSuccess('保存成功');
      if (this.state.activeTab === 'up') {
        this.upList.current?.reset();
      } else {
        this.downList.current?.reset();
      }
    })
  }
}
